/* eslint
    prettier/prettier: "off",
    func-names: "off",
    no-undef: "off",
    eqeqeq: "off",
    no-shadow: "off",
    no-unused-vars: "off",
    object-shorthand: "off"
*/
(function($) {
  Drupal.behaviors.DonationEcardJS = {
    attach: function (context, settings) {

      var flagged = 0;
      // Get checkbox to enable/disable ecard fields and enable required fields where applicable
      var ecardCheckBox = $(".webform-client-form input").filter(function() {
        return ((" " + $(this).attr('id') + " ").match(/edit-submitted-send-an-ecard-ecard-fields-yes-send-an-ecard-*/) != null);
      });

      function toggleEcardFields(flagged) {

        // Get hidden field to
        if(flagged == 0) {
          ecardCheckBox.attr('checked', true);
          flagged = 1
          $('#edit-submitted-send-an-ecard-ecard-field-ui-required-fields').val('1')
          value = $('#edit-submitted-send-an-ecard-ecard-field-ui-required-fields').val()
          $("label[for='edit-submitted-send-an-ecard-ecard-fields-recipient-email-address']").append('<span title="This field is required." class="form-required required-flagged">*</span>')
          $('#edit-submitted-send-an-ecard-ecard-fields-recipient-email-address').removeAttr('disabled', 'disabled')
          $("label[for='edit-submitted-send-an-ecard-ecard-fields-ecard-message']").append('<span title="This field is required." class="form-required required-flagged">*</span>')
          $('#edit-submitted-send-an-ecard-ecard-fields-ecard-message').removeAttr('disabled')
        }
        else {
          ecardCheckBox.attr('checked', false);
          flagged = 0
          $('#edit-submitted-send-an-ecard-ecard-field-ui-required-fields').val('0')
          $(".required-flagged").remove()
          $('#edit-submitted-send-an-ecard-ecard-fields-recipient-email-address').attr('disabled', 'disabled')
          $('#edit-submitted-send-an-ecard-ecard-fields-ecard-message').attr('disabled', 'disabled')
        }
        return flagged;
      }

      // Initialize ecard fields
      if (ecardCheckBox.is(':checked')) {
        flagged = 1
        $("label[for='edit-submitted-send-an-ecard-recipient-e-mail-address']").append('<span title="This field is required." class="form-required required-flagged">*</span>')
        $('#edit-submitted-send-an-ecard-ecard-fields-recipient-email-address').addClass('required key-validate');
        $("label[for='edit-submitted-send-an-ecard-ecard-message']").append('<span title="This field is required." class="form-required required-flagged">*</span>')
        $('#edit-submitted-send-an-ecard-ecard-fields-ecard-message').addClass('required key-validate');
      } else {
        flagged = 0
        $('#edit-submitted-send-an-ecard-ecard-fields-recipient-email-address').attr('disabled', 'disabled')
        $('#edit-submitted-send-an-ecard-ecard-fields-ecard-message').attr('disabled', 'disabled')
      }
      $('#edit-submitted-send-an-ecard-ecard-fields-recipient-email-address').addClass('required key-validate');
      $('#edit-submitted-send-an-ecard-ecard-fields-ecard-message').addClass('required key-validate');
      if ($.browser.msie) {
        ecardCheckBox.click(function () {
          flagged = toggleEcardFields(flagged);
        });
      } else {
        ecardCheckBox.change(function() {
          flagged = toggleEcardFields(flagged);
        });
      }
    }
  }
})(jQuery);

